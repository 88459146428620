import "./index.css";
import { useSelector } from "react-redux";

export default function Rules() {
  const rules = useSelector((state) => state.user.rules);

  return (
    <div
      style={{
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        height: "1000px",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <h2>Правила</h2>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "1800px",
          marginTop: "30px",
        }}
      ></div>

      <div style={{ marginTop: "20px", width: "80%" }}>
        <ul>
          {rules.map((el) => {
            return (
              <div key={el.title} style={{ marginTop: "20px" }}>
                <h4>{el.title}</h4>

                {el.data.map((elem) => {
                  return (
                    <li key={elem.point} style={{ marginTop: "10px" }}>
                      {elem.text}
                    </li>
                  );
                })}
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
