import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField, Button } from "@mui/material";
import "../index.css";
import {
  getGamesForModerator,
  postNews,
  getUsersForModerator,
} from "../redux/asyncThunk/userActions";

export default function News() {
  const dispatch = useDispatch();

  const [text, setText] = useState("");
  const [news, setNews] = useState([]);

  const user = useSelector((state) => state.user?.data);
  const info = useSelector((state) => state.user?.info);

  useEffect(() => {
    if (user._id) {
      dispatch(getGamesForModerator({ id: user._id }));
      dispatch(getUsersForModerator({ id: user._id }));
    }
  }, [dispatch, user._id]);

  useEffect(() => {
    if (info) {
      const newArr = info.toReversed();
      setNews(newArr);
    }
  }, [info]);

  return (
    <div
      style={{
        display: "flex",
        gap: "20px",
        padding: "20px 20px",
        flexWrap: "wrap",
        justifyContent: "space-around",
      }}
    >
      <div>
        <div
          style={{
            padding: "10px 0",
            display: "flex",
            gap: "20px",
          }}
        >
          <div
            style={{
              border: "1px solid black",
              maxWidth: "750px",
              width: "100%",
              borderRadius: "3px",
              backgroundColor: "rgba(45, 157, 188, 0.5)",
            }}
          >
            {news?.map((el, index) => {
              return (
                <div style={{ padding: "10px 5px" }} key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{el.type}</div>
                    <div>{el.date}</div>
                  </div>
                  <div style={{ padding: "3px 0px" }}>{el.description}</div>
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Введите текст новости"
              variant="outlined"
              multiline
              rows={4}
              sx={{ width: "500px" }}
              onChange={(event) => setText(event.target.value)}
            />
            <Button
              sx={{ height: "30px" }}
              size="small"
              variant="contained"
              onClick={() =>
                dispatch(postNews({ id: user._id, description: text }))
              }
            >
              Добавить новость
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
