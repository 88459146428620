import React from "react";

const FileReceiver = (props) => {
  const { handleRequestFile, videoSrc, buttonsData } = props;

  return (
    <div>
      {videoSrc && (
        <video controls style={{ width: "240px" }}>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {buttonsData?.length ? (
        <>
          {buttonsData.map((el, index) => {
            return (
              <div
                onClick={() => handleRequestFile(el.id)}
                key={index}
                style={{
                  background: "white",
                  display: "flex",
                  margin: "5px 0px",
                  padding: "3px 0px",
                  justifyContent: "center",
                  borderRadius: "3px",
                  cursor: "pointer",
                }}
              >
                {el.data?.label}
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
};

export default FileReceiver;
