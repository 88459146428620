import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NodeToolbar } from "reactflow";
import { loadVideoScene, saveGame } from "../../redux/asyncThunk/userActions";
import { userSlice } from "../../redux/user/userSlice";
import "reactflow/dist/style.css";
import "../index.css";

import { Box, TextField, Button } from "@mui/material";

const { addMessage } = userSlice;

const prodURL = "https://club-lab.ru";
const url =
  window.location.hostname === "localhost" ? "http://localhost:3004" : prodURL;

export const firstSceneNodes = [
  {
    id: "1-group",
    type: "group",
    position: { x: 0, y: 0 },
    style: {
      backgroundColor: "rgba(45, 157, 188, 0.5)",
      width: 160,
      height: 150,
    },
  },
  {
    id: "1",
    position: { x: 10, y: 5 },
    data: {
      nodeType: "menu",
      video: "",
      hasVideo: false,
      videopath: "",
      screenpath: ``,
    },
    type: "first-scene-node",
    className: "light",
    style: {
      backgroundColor: "rgba(125, 157, 188, 0.5)",
      width: 140,
      height: 100,
    },
    extent: "parent",
    parentNode: "1-group",
    parentId: "1-group",
    draggable: false,
  },
];

export function FirstSceneNode(props) {
  const { data } = props;

  const selectedGameId = useSelector((state) => state.user?.selectedGameId);
  const game = useSelector((state) => state.user?.game);
  const user = useSelector((state) => state.user?.data);
  const [buttonText, setButtonText] = useState("");
  const [action, setAction] = useState("");

  const dispatch = useDispatch();

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    const sizeInMegabytes = file.size / (1024 * 1024);

    if (Math.floor(sizeInMegabytes) < 100) {
      dispatch(
        loadVideoScene({
          userId: user._id,
          selectedGameId,
          scene: 1,
          file,
          game,
        })
      );
    } else {
      dispatch(
        addMessage({
          type: "error",
          message: "Файл должен быть менее 100мб",
        })
      );
    }
  };

  const handleAddButtonNode = () => {
    const checkButtonsInNode = game.nodes.find((el) => el.type === "input");

    const checkButtonsLength = game.nodes.filter((el) => el.type === "input");

    if (checkButtonsLength.length === 2) {
      return dispatch(
        addMessage({
          type: "error",
          message: "Сейчас вы можете добавить только две кнопки",
        })
      );
    }

    const busyPosition =
      checkButtonsLength?.length &&
      checkButtonsLength[0].data.buttonPosiiton === "top"
        ? "bottom"
        : "top";

    const buttonPosiiton = {
      x: 10,
      y: 0,
    };

    const newId = !checkButtonsInNode
      ? "1-1"
      : `1-${Number(checkButtonsInNode.id.split("-")[1]) + 1}`;

    if (busyPosition === "bottom") {
      buttonPosiiton.y = 128;
    } else {
      buttonPosiiton.y = 110;
    }

    const newButtonNode = {
      id: newId,
      data: { label: buttonText, buttonPosiiton: busyPosition },
      position: buttonPosiiton,
      style: {
        width: 140,
        height: 15,
      },
      parentId: "1-group",
      sourcePosition: "right",
      type: "input",
      extent: "parent",
      parentNode: "1-group",
      draggable: false,
    };

    dispatch(
      saveGame({
        selectedGameId,
        nodes: [...game.nodes, newButtonNode],
        edges: game.edges,
      })
    );
  };
  console.log(prodURL + data?.screenpath, " data?.screenpath");
  return (
    <>
      <NodeToolbar isVisible={undefined}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              marginBottom: "10px",
            }}
          >
            {action === "button" ? (
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <TextField
                  sx={{ bgcolor: "white" }}
                  id="outlined-basic"
                  label="Текст кнопки"
                  variant="outlined"
                  size="small"
                  onChange={(event) => setButtonText(event.target.value)}
                />
                <Button
                  variant="contained"
                  onClick={() => handleAddButtonNode()}
                >
                  Добавить
                </Button>
              </Box>
            ) : null}
            {action === "video" ? (
              <input
                type="file"
                name="videoFile"
                accept="video/*"
                onChange={handleVideoChange}
              />
            ) : null}
          </Box>
          <Box sx={{ display: "flex" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                className="toolbar_button"
                onClick={() => setAction("video")}
              >
                Добавить видео
              </button>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <button
                className="toolbar_button"
                onClick={() => setAction("button")}
              >
                Добавить кнопку
              </button>
            </div>
          </Box>
        </Box>
      </NodeToolbar>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <div
          style={{
            height: "140px",
            border: "1px solid #b6c3cf",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#2F5478",
            width: "138px",
          }}
        >
          {data?.hasVideo ? (
            <img
              src={`${url}/${
                data?.screenpath
              }?timestamp=${new Date().getTime()}`}
              alt={`Screenshot`}
              style={{ width: "200px", height: "100px", objectFit: "contain" }}
            />
          ) : (
            "Нажмите в эту область для действия"
          )}
        </div>
      </div>
    </>
  );
}
