import React from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MovingIcon from "@mui/icons-material/Moving";
import GradeIcon from "@mui/icons-material/Grade";
import CheckIcon from "@mui/icons-material/Check";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CustomizedTimeline from "../components/CustomizedTimeline";
import AccordionComponent from "../components/Accordion";
import "./index.css";

export default function MainPage() {
  return (
    <div style={{ backgroundColor: "#FCFCFC" }}>
      <div className="main_block">
        <div style={{ display: "flex" }}>
          <div className="main_text">
            <div>
              <h1
                style={{
                  fontSize: "40px",
                  fontWeight: "bolder",
                  letterSpacing: "3px",
                }}
              >
                MOGAME
              </h1>
              <div>-</div>
              <h1 style={{ letterSpacing: "5px" }}>
                Создавай свой уникальный контент
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "50px",
        }}
      >
        <div
          style={{
            maxWidth: "1100px",
            backgroundColor: "rgba(125, 157, 188, 0.5)",
            width: "100%",
            padding: "40px 0",
          }}
        >
          <div className="column_list">
            <div className="column_list_item">
              <div className="subtitle">
                <GradeIcon className="icons" />
              </div>

              <ul>
                <li className="list_item">Уникальный контент</li>
              </ul>
            </div>
            <div className="column_list_item">
              <div className="subtitle">
                <MovingIcon className="icons" />
              </div>

              <ul>
                <li className="list_item">Новые подписчики</li>
              </ul>
            </div>
            <div className="column_list_item">
              <div className="subtitle">
                <AttachMoneyIcon className="icons" />
              </div>

              <ul>
                <li className="list_item">Монетизация</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "0 0 40px 0",
        }}
      >
        <div
          className="block"
          style={{
            maxWidth: "1100px",
            backgroundColor: "rgba(125, 157, 188, 0.5)",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              maxWidth: "600px",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <CheckIcon
                  sx={{
                    fontSize: "45px",
                    color: "#305478",
                    padding: "5px 5px",
                  }}
                />
              </div>
              <div style={{ fontSize: "20px" }}>Бесплатно</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <SupportAgentIcon
                  sx={{
                    fontSize: "45px",
                    color: "#305478",
                    padding: "5px 5px",
                  }}
                />
              </div>
              <div style={{ fontSize: "20px" }}>Поддержка</div>
            </div>
          </div>
        </div>
      </div>

      <div className="block">
        <div>
          <div className="title">
            <h2>Создавай свои сценарии и пусть пользователи пройдут по нему</h2>
          </div>

          <div
            style={{
              display: "flex",
              gap: "40px",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div className="column_list">
              <CustomizedTimeline />
            </div>
            <div className="column_list">
              <video
                controls
                style={{ width: "100%", maxWidth: "500px", height: "150px" }}
              >
                <source src="/images/flowers-3.mp4" type="video/webm" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="block" style={{ backgroundColor: "#F4F4F4" }}>
        <div
          style={{
            display: "flex",
            padding: "20px 20px",
          }}
        >
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <div>
              <div style={{ textAlign: "center", padding: "10px 0" }}>
                <div className="title">
                  <h2>Создавать это просто</h2>
                </div>
              </div>
              <img
                src="/images/tg1.png"
                alt="tg-pic"
                style={{
                  width: "100%",
                  maxWidth: "1000px",
                  maxHeight: "715px",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <img
              src="/images/tg_main.png"
              alt="tg-pic"
              style={{
                width: "100%",
                maxWidth: "700px",
                maxHeight: "715px",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </div>
      <div className="block">
        <div>
          <div className="title">
            <h2>FAQ</h2>
          </div>
          <AccordionComponent />
        </div>
      </div>
      <div className="footer"></div>
    </div>
  );
}
