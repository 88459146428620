import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { userSlice } from "../redux/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const { selectProject, showDialog, resetSelectedProject, closeDialog } =
  userSlice;

export default function SideMenu() {
  const dispatch = useDispatch();
  const [links, setLinks] = useState([]);
  const games = useSelector((state) => state.user?.gamesLinks);
  const selectedGameId = useSelector((state) => state.user?.selectedGameId);

  const handleActive = (id) => {
    dispatch(selectProject(id));
  };

  useEffect(() => {
    if (games.length) {
      setLinks(games);
    }
  }, [games]);

  return (
    <div className="sideMenu">
      <Button
        variant="contained"
        size="small"
        onClick={() => dispatch(showDialog("new"))}
      >
        Создать сценарий
      </Button>

      <div style={{ padding: "10px 0" }}>
        <Link to={"/main"}>
          <Button
            variant="contained"
            size="small"
            onClick={() => dispatch(resetSelectedProject())}
            color="success"
          >
            Рабочий стол
          </Button>
        </Link>
      </div>

      <div
        style={{
          padding: "20px 0px",
          textAlign: "center",
          width: "100%",
          color: "white",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Ваши игры:</p>
        {!links?.length ? <div>У вас пока нет созданных игр</div> : null}
        <ul>
          {links?.map((el, i) => {
            return (
              <Link
                to={`/main/${el._id}`}
                state={{ projectId: el._id, type: "edit" }}
                style={{ textDecoration: "none" }}
                key={el._id + i + Date.now()}
              >
                <li
                  onClick={() => {
                    dispatch(resetSelectedProject());
                    handleActive(el._id);
                    dispatch(closeDialog());
                  }}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    padding: "5px 0",
                    margin: "5px 0",
                    justifyContent: "center",
                    textDecoration: "none",
                    color: selectedGameId === el._id ? "#305478" : "white",
                    backgroundColor: selectedGameId === el._id ? "white" : "",
                    fontWeight: "bold",
                  }}
                >
                  {el.title}
                </li>
              </Link>
            );
          })}
        </ul>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <div style={{ width: "80px", borderBottom: "1px solid white" }}></div>
        </div>
        <p style={{ fontWeight: "bold", marginTop: "10px" }}>Обучение:</p>
      </div>
    </div>
  );
}
