import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import { getGamesData, userSlice } from "../redux/user/userSlice";
import { loadPoster, deletePoster } from "../redux/asyncThunk/userActions";
import { Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
import InputFileUpload from "./UploadFile";
import "../index.css";

const { selectProject } = userSlice;

export default function Author() {
  const dispatch = useDispatch();

  const [gamesData, setGamesData] = useState([]);
  const [news, setNews] = useState([]);

  const data = useSelector((state) => state.user?.games);
  const user = useSelector((state) => state.user?.data);
  const info = useSelector((state) => state.user?.info);

  const handleAddPoster = (event, id) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      const base64String = reader.result.split(",")[1];

      dispatch(
        loadPoster({
          gameId: id,
          base64String,
          onSucces: () => dispatch(getGamesData({ id: user._id })),
        })
      );
    };
  };

  useEffect(() => {
    if (data) {
      setGamesData(data);
    }
  }, [data]);

  useEffect(() => {
    if (info) {
      const newArr = info?.toReversed();
      setNews(newArr);
    }
  }, [info]);

  return (
    <div>
      <>
        <div
          style={{
            display: "flex",
            gap: "20px",
            padding: "20px 20px",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {!gamesData.length ? (
            <div style={{ fontSize: "24px", fontWeight: "bold" }}>
              Вы еще не создали свой первый сценарий
            </div>
          ) : (
            <>
              {gamesData?.map((el) => {
                return (
                  <div
                    key={el._id}
                    style={{
                      border: "2px solid #2F5478",
                      width: "340px",
                    }}
                  >
                    <div style={{ textAlign: "center", padding: "5px 0" }}>
                      {el.title}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      {el.poster ? (
                        <div
                          className="image-preview"
                          style={{
                            boxSizing: "border-box",
                            height: "220px",
                            width: "300px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={`data:image/jpeg;base64,` + el.poster}
                            alt="Uploaded"
                          />
                        </div>
                      ) : (
                        <div className="upload-container">
                          <div className="upload-icon">
                            <NoPhotographyIcon />
                          </div>
                          {/* <div className="upload-text">Загрузите постер</div> */}
                        </div>
                      )}

                      {el.poster ? (
                        <Tooltip title="Удалить постер" placement="top">
                          <DeleteIcon
                            onClick={() => {
                              dispatch(
                                deletePoster({
                                  gameId: el._id,
                                  onSucces: () =>
                                    dispatch(getGamesData({ id: user._id })),
                                })
                              );
                            }}
                            sx={{
                              position: "absolute",
                              right: 25,
                              top: 5,
                              color: "red",
                              cursor: "pointer",
                              border: "1px solid red",
                              borderRadius: "3px",
                              bgcolor: "white",
                            }}
                          />
                        </Tooltip>
                      ) : null}
                    </div>

                    <div style={{ padding: "5px 3px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "5px 15px",
                        }}
                      >
                        <Tooltip title="Настройки проекта" placement="top">
                          <Link
                            to={`/main/${el._id}`}
                            state={{ projectId: el._id, type: "settings" }}
                          >
                            <SettingsIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => dispatch(selectProject(el._id))}
                            />
                          </Link>
                        </Tooltip>
                        <InputFileUpload
                          handleAddPoster={handleAddPoster}
                          id={el._id}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <div>
            <h4 style={{ textAlign: "center", padding: "3px 0px" }}>
              Новости платформы
            </h4>

            <div
              style={{
                border: "1px solid black",
                maxWidth: "750px",
                width: "100%",
                borderRadius: "3px",
                backgroundColor: "rgba(45, 157, 188, 0.5)",
              }}
            >
              {news?.map((el, index) => {
                return (
                  <div style={{ padding: "10px 5px" }} key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{el.type}</div>
                      <div>{el.date}</div>
                    </div>
                    <div style={{ padding: "3px 0px" }}>{el.description}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
