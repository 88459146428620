import { useSelector, useDispatch } from "react-redux";
import TelegramView from "../components/TelegramView";
import { Routes, Route } from "react-router-dom";
import Game from "../components/Game";
import Dashbord from "../components/Dashbord";
import Dialog from "../components/Dialog";
import SideMenu from "../components/SideMenu";
import Profile from "../components/Profile";
import { useEffect } from "react";
import { getRules, getInfo } from "../redux/asyncThunk/userActions";
import { userSlice, getGamesData } from "../redux/user/userSlice";

const { clearMessages } = userSlice;

export default function PersonalPage() {
  const dispatch = useDispatch();

  const showDialog = useSelector((state) => state.user.showDialog);
  const rules = useSelector((state) => state.user.rules);
  const info = useSelector((state) => state.user.info);
  const userId = useSelector((state) => state.user.data._id);

  useEffect(() => {
    if (!rules.lenght || !info.lenght) {
      dispatch(getRules());
      dispatch(getInfo());
    }
    dispatch(getGamesData({ id: userId }));
    dispatch(clearMessages());
  }, [dispatch, info.lenght, rules.lenght, userId]);

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <SideMenu />

      <div className="workArea">
        <Routes>
          <Route path="/" element={<Dashbord />} />
          <Route path="/:id" element={<Game />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </div>

      <TelegramView />

      {showDialog.isOpen ? <Dialog /> : null}
    </div>
  );
}
