import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    const data = useSelector((state) => state.user.data);

    if (!data) {
        return <Navigate to="/" />;
    }

    return children;
};

export default PrivateRoute;
