import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../index.css";
import {
  getGamesForModerator,
  getUsersForModerator,
} from "../redux/asyncThunk/userActions";

export default function UserTable() {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.user?.users);
  const user = useSelector((state) => state.user?.data);

  useEffect(() => {
    if (user._id) {
      dispatch(getGamesForModerator({ id: user._id }));
      dispatch(getUsersForModerator({ id: user._id }));
    }
  }, [dispatch, user._id]);

  return (
    <ul>
      <div
        style={{
          display: "flex",
          backgroundColor: "#b6c3cf",
          height: "40px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "250px", textAlign: "center" }}>ID</div>
        <div style={{ width: "200px", textAlign: "center" }}>Логин</div>
        <div style={{ width: "200px", textAlign: "center" }}>TG-ID</div>
        <div style={{ width: "100px", textAlign: "center" }}>18+</div>
        <div style={{ width: "150px", textAlign: "center" }}>День рождения</div>
        <div style={{ width: "200px", textAlign: "center" }}>
          Доступные ключи
        </div>
      </div>
      {data?.map((el, index) => {
        return (
          <div className="tableLine" key={el._id + index}>
            <div style={{ width: "250px", textAlign: "center" }}>{el._id}</div>
            <div style={{ width: "200px", textAlign: "center" }}>
              {el.login}
            </div>
            <div style={{ width: "200px", textAlign: "center" }}>{el.tgId}</div>
            <div style={{ width: "100px", textAlign: "center" }}>
              {el.accessAdultContent}
            </div>
            <div style={{ width: "150px", textAlign: "center" }}>
              {el.birthday}
            </div>
            <div style={{ width: "200px", textAlign: "center" }}>
              {el.limit}
            </div>
          </div>
        );
      })}
    </ul>
  );
}
