import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  FormControl,
  Button,
  TextField,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import { generateKey, getHelpMessage } from "../redux/asyncThunk/userActions";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { saveGameSettings } from "../redux/asyncThunk/userActions";
import "./index.css";

export default function Settings() {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [settings, setSettings] = useState({
    title: "",
    category: "",
    description: "",
    private_access: false,
    adult_content: false,
    published: false,
  });

  const game = useSelector((state) => state.user.game);
  const user = useSelector((state) => state.user?.data);

  const handleChange = (event, property) => {
    const temp = { ...settings };
    temp[property] = event.target.value;

    setSettings(temp);
  };

  useEffect(() => {
    if (game) {
      setSettings({
        title: game.title,
        category: game.category,
        description: game.description,
        private_access: game.settings?.private_access,
        adult_content: game.settings?.adult_content,
        published: game.settings?.published,
      });
      setData({
        ...game,
        scenesValue: game.nodes.filter((el) => el.type === "group")?.length,
      });
    }
  }, [game]);

  const handleUpdateSettings = () => {
    dispatch(
      saveGameSettings({
        game: game._id,
        title: settings.title,
        description: settings.description,
        category: settings.category,
        settings: {
          private_access: settings.private_access,
          adult_content: settings.adult_content,
          published: settings.published,
        },
      })
    );
  };

  return (
    <div
      style={{
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        height: "1000px",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <h2>Настройки</h2>
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          width: "90%",
          marginTop: "30px",
          gap: "20px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className="settingsRow">
            <div>Название проекта</div>
            <div>
              <TextField
                onChange={(event) => handleChange(event, "title")}
                id="title"
                label="Введите название"
                variant="outlined"
                size="small"
                value={settings?.title}
                sx={{
                  width: "300px",
                }}
              />
            </div>
          </div>
          <div className="settingsRow">
            <div>Категория</div>
            <FormControl sx={{ width: "300px" }}>
              <InputLabel id="category">Выберите категорию</InputLabel>
              <Select
                labelId="category"
                id="category"
                value={settings.category}
                label="category"
                onChange={(event) => handleChange(event, "category")}
              >
                <MenuItem value={"Квест"}>Квест</MenuItem>
                <MenuItem value={"Туризм"}>Туризм</MenuItem>
                <MenuItem value={"Adult"}>18+</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="settingsRow">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <span>Доступ к проекту</span>
              <Tooltip title="Нажмите для получения подсказки" placement="top">
                <HelpOutlineOutlinedIcon
                  onClick={() => dispatch(getHelpMessage({ key: "access" }))}
                  sx={{ cursor: "pointer", fontSize: "14px" }}
                />
              </Tooltip>
            </div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ width: "300px", justifyContent: "space-between" }}
                value={settings.private_access}
                id="private_access"
                onChange={(event) => handleChange(event, "private_access")}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Публичный"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Приватный"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="settingsRow">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <span>Контент для взрослых:</span>
              <Tooltip title="Нажмите для получения подсказки" placement="top">
                <HelpOutlineOutlinedIcon
                  onClick={() =>
                    dispatch(getHelpMessage({ key: "adult_content" }))
                  }
                  sx={{ cursor: "pointer", fontSize: "14px" }}
                />
              </Tooltip>
            </div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={settings.adult_content}
                onChange={(event) => handleChange(event, "adult_content")}
                sx={{ width: "300px", justifyContent: "space-between" }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Да" />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Нет"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="settingsRow">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <span>Статус проекта</span>
              <Tooltip title="Нажмите для получения подсказки" placement="top">
                <HelpOutlineOutlinedIcon
                  onClick={() => dispatch(getHelpMessage({ key: "status" }))}
                  sx={{ cursor: "pointer", fontSize: "14px" }}
                />
              </Tooltip>
            </div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={settings.published}
                onChange={(event) => handleChange(event, "published")}
                sx={{ width: "300px", justifyContent: "space-between" }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Опубликован"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="В работе"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="settingsRow">
            <div>Стартовый пост</div>
            <TextField
              id="description"
              label="Введите текст и сохраните проект после редактирования"
              variant="outlined"
              multiline={true}
              minRows={"3"}
              width="300px"
              sx={{
                width: "300px",
              }}
              onChange={(event) => handleChange(event, "description")}
            />
          </div>
          <div className="settingShortRow">
            <Button
              variant="contained"
              size="small"
              color="success"
              onClick={() => handleUpdateSettings()}
            >
              Сохранить
            </Button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h4>Стартовый пост вашей игры</h4>
            <div
              style={{
                border: "2px solid black",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "400px",
                height: "350px",
                flexDirection: "column",
                margin: "3px 0",
              }}
            >
              <h4>MoGaMe</h4>
              <div
                className="image-preview"
                style={{
                  boxSizing: "border-box",
                  height: "220px",
                  width: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {data?.poster ? (
                  <img
                    style={{ maxHeight: "200px" }}
                    src={`data:image/jpeg;base64,` + data?.poster}
                    alt="Uploaded"
                  />
                ) : (
                  <Tooltip placement="top" title="Постер не загружен">
                    <ImageNotSupportedOutlinedIcon />
                  </Tooltip>
                )}
              </div>
              {settings?.description || settings.title ? (
                <div
                  style={{
                    width: "90%",
                    border: "1px solid #305478",
                    borderRadius: "10px",
                    backgroundColor: "#305478",
                    color: "white",
                    padding: "3px 0",
                  }}
                >
                  <p>{settings.title}</p>
                  <p>{settings.description}</p>
                </div>
              ) : null}
              <Button variant="contained" size="small" sx={{ margin: "3px 0" }}>
                Начать
              </Button>
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <h4>Пример стартового поста</h4>
            <div
              style={{
                border: "2px solid black",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "400px",
                height: "350px",
                flexDirection: "column",
              }}
            >
              <h4>MoGaMe</h4>
              <div
                className="image-preview"
                style={{
                  height: "220px",
                  width: "300px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    objectFit: "cover",
                    maxHeight: "200px",
                  }}
                  src={process.env.PUBLIC_URL + "/images/bg.png"}
                  alt="Background"
                />
              </div>
              <div
                style={{
                  width: "90%",
                  border: "1px solid #305478",
                  borderRadius: "10px",
                  backgroundColor: "#305478",
                  color: "white",
                  padding: "3px 0",
                  margin: "3px 0",
                }}
              >
                <p>Долг платежом красен</p>
                <p>
                  Привет! Ты готов начать увлекательное приключение со мной?
                </p>
              </div>
              <Button variant="contained" size="small" sx={{ margin: "3px 0" }}>
                Начать
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h3>Доступ к проекту</h3>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            marginTop: "30px",
            gap: "25px",
          }}
        >
          <div>
            <div className="settingMiddleRow">
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <span>Количество доступных ключей:</span>
                <Tooltip
                  title="Нажмите для получения подсказки"
                  placement="top"
                >
                  <HelpOutlineOutlinedIcon
                    onClick={() =>
                      dispatch(getHelpMessage({ key: "keysCounter" }))
                    }
                    sx={{ cursor: "pointer", fontSize: "14px" }}
                  />
                </Tooltip>
              </div>
              <div>{user?.limit}</div>
            </div>
            <div className="settingMiddleRow">
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <span>Количество созданных ключей:</span>
                <Tooltip
                  title="Нажмите для получения подсказки"
                  placement="top"
                >
                  <HelpOutlineOutlinedIcon
                    onClick={() =>
                      dispatch(getHelpMessage({ key: "keysCreated" }))
                    }
                    sx={{ cursor: "pointer", fontSize: "14px" }}
                  />
                </Tooltip>
              </div>
              <div>{user?.keys?.length === 0 ? "0" : user?.keys?.length}</div>
            </div>
            <div className="settingMiddleRow">
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <span>Количество использованных ключей:</span>
                <Tooltip
                  title="Нажмите для получения подсказки"
                  placement="top"
                >
                  <HelpOutlineOutlinedIcon
                    onClick={() =>
                      dispatch(getHelpMessage({ key: "usedKeys" }))
                    }
                    sx={{ cursor: "pointer", fontSize: "14px" }}
                  />
                </Tooltip>
              </div>
              <div>
                {user?.keys?.filter((el) => el.availeble === false).length}
              </div>
            </div>
            <Button
              sx={{ height: "30px" }}
              size="small"
              component="label"
              variant="contained"
              onClick={() =>
                dispatch(
                  generateKey({
                    game: data._id,
                    user: data.owner,
                  })
                )
              }
            >
              Сгенерировать ключ доступа
            </Button>
          </div>

          <ul>
            <div
              style={{
                display: "flex",
                gap: "3px",
                backgroundColor: "#b6c3cf",
                width: "800px",
                justifyContent: "space-between",
                height: "40px",
                alignItems: "center",
              }}
            >
              <div style={{ width: "400px", textAlign: "center" }}>Ключ</div>
              <div style={{ width: "200px", textAlign: "center" }}>
                Использован
              </div>
              <div style={{ width: "200px", textAlign: "center" }}>
                Дата использования
              </div>
            </div>
            {user?.keys
              ?.filter((el) => el.game === data?._id)
              .map((el) => {
                return (
                  <li
                    style={{ display: "flex", marginTop: "7px" }}
                    key={el.key}
                  >
                    <div style={{ width: "400px", textAlign: "center" }}>
                      {el.key}
                    </div>
                    <div style={{ width: "200px", textAlign: "center" }}>
                      {el.availeble ? "Нет" : "Да"}
                    </div>
                    <div style={{ width: "200px", textAlign: "center" }}>
                      {el.usageDate ? el.usageDate : "Не использован"}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}
