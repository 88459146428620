import React, { useState, useEffect } from "react";
import { TextField, Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { loginUser, registrationUser } from "../redux/asyncThunk/userActions";
import { useDispatch, useSelector } from "react-redux";
import { userSlice } from "../redux/user/userSlice";

const { clearMessages } = userSlice;

export default function Login() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.data);
  const messages = useSelector((state) => state.user.messages);

  useEffect(() => {
    if (user) {
      navigate("/main");
    }
  }, [navigate, user]);

  const [loginData, setLoginData] = useState({
    login: "",
    password: "",
  });
  const [regData, setRegData] = useState({
    regLogin: "",
    regPassword: "",
    confirmRegPassword: "",
    accessKey: "",
  });
  const [message, setMessage] = useState({
    type: "",
    message: "",
  });

  useEffect(() => {
    setLoginData({
      login: "",
      password: "",
    });
    setRegData({
      regLogin: "",
      regPassword: "",
      confirmRegPassword: "",
    });
    setMessage({
      type: "",
      message: "",
    });
    dispatch(clearMessages());
  }, [dispatch, location]);

  useEffect(() => {
    setMessage(...messages);
  }, [messages]);

  const changeLoginData = (event) => {
    setMessage({
      type: "",
      message: "",
    });
    setLoginData((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));
  };

  const changeRegData = (event) => {
    setMessage({
      type: "",
      message: "",
    });
    setRegData((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));
  };

  const handleLoginButton = () => {
    if (loginData.login && loginData.password) {
      dispatch(
        loginUser({ login: loginData.login, password: loginData.password })
      );
    } else {
      if (!!loginData.login.length || !!loginData.password.length) {
        setMessage({
          type: "error",
          message: "Не введены логин или пароль",
        });
      }
    }
  };

  const handleRegButton = () => {
    if (
      regData.regLogin &&
      regData.regPassword &&
      regData.confirmRegPassword &&
      regData.accessKey
    ) {
      const checkPasswords =
        regData.regPassword === regData.confirmRegPassword ? true : false;

      if (checkPasswords) {
        dispatch(
          registrationUser({
            login: regData.regLogin,
            password: regData.regPassword,
            key: regData.accessKey,
          })
        );
      } else {
        setMessage({
          type: "error",
          message: "Пароли не совпадают",
        });
      }
    } else {
      if (!!regData.regLogin.length || !!regData.regPassword.length) {
        setMessage({
          type: "error",
          message: "Заполните все поля",
        });
      }
    }
  };

  return (
    <div>
      <div className="main_block_login">
        <div
          className={location.pathname === "/login" ? "login_form" : "reg_form"}
        >
          {location.pathname === "/login" ? (
            <Box
              component="form"
              onChange={(event) => changeLoginData(event)}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                sx={{ color: "white", fontSize: "18px", fontWeight: "bold" }}
              >
                Авторизация
              </Typography>
              <TextField
                id="login"
                label="Логин"
                variant="filled"
                sx={{ bgcolor: "white", width: "80%" }}
                value={loginData.login || ""}
              />
              <TextField
                id="password"
                label="Пароль"
                variant="filled"
                type="password"
                sx={{ bgcolor: "white", width: "80%" }}
                value={loginData.password || ""}
              />
              {message?.message ? (
                <Box sx={{ color: "white" }}>{message.message}</Box>
              ) : null}
              <Button
                variant="contained"
                onClick={() => handleLoginButton(loginData)}
              >
                Войти
              </Button>
            </Box>
          ) : (
            <Box
              onChange={(event) => changeRegData(event)}
              component={"form"}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // height: "100%",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                sx={{ color: "white", fontSize: "18px", fontWeight: "bold" }}
              >
                Регистрация
              </Typography>
              <TextField
                id="regLogin"
                label="Логин"
                variant="filled"
                value={regData.regLogin || ""}
                sx={{ bgcolor: "white", width: "80%" }}
              />
              <TextField
                id="regPassword"
                label="Пароль"
                variant="filled"
                type="password"
                value={regData.regPassword || ""}
                sx={{ bgcolor: "white", width: "80%" }}
              />
              <TextField
                id="confirmRegPassword"
                label="Повторите пароль"
                variant="filled"
                type="password"
                value={regData.confirmRegPassword || ""}
                sx={{ bgcolor: "white", width: "80%" }}
              />
              <TextField
                id="accessKey"
                label="Введите ключ доступа"
                variant="filled"
                value={regData.accessKey || ""}
                sx={{ bgcolor: "white", width: "80%" }}
              />
              {message?.message ? (
                <Box sx={{ color: "white" }}>{message.message}</Box>
              ) : null}
              <Button
                variant="contained"
                onClick={() => handleRegButton(regData)}
              >
                Регистрация
              </Button>
            </Box>
          )}
        </div>
      </div>
      <div className="footer"></div>
    </div>
  );
}
