import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import { addNewGame } from "../redux/asyncThunk/userActions";
import { getGamesData } from "../redux/user/userSlice";
import { useNavigate } from "react-router-dom";

export default function NewGame() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const games = useSelector((state) => state.user?.games);
  const user = useSelector((state) => state.user?.data);

  const [textProject, setTextProject] = useState(``);

  const checkGamesTitle = useCallback(
    (count) => {
      const check = games.find((el) => el.title === `Новый проект-${count}`);

      if (check) {
        return false;
      } else {
        return true;
      }
    },
    [games]
  );

  useEffect(() => {
    if (!games?.length) {
      setTextProject(`Новый проект-1`);
    } else {
      let count = 1;

      while (checkGamesTitle(count) !== true) {
        count++;
      }
      setTextProject(`Новый проект-${count}`);
    }
  }, [checkGamesTitle, games]);

  return (
    <div
      style={{
        padding: "15px 20px",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <div style={{ textAlign: "center" }}>Создайте проект</div>

      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <div>Название проекта:</div>
        <TextField
          value={textProject}
          size="small"
          onChange={(ev) => setTextProject(ev.target.value)}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            let onSuccess;

            onSuccess = () => {
              dispatch(getGamesData({ id: user._id }))
              navigate("/main", { replace: true });
            };

            dispatch(addNewGame({ userId: user._id, textProject, onSuccess }));
          }}
        >
          Создать
        </Button>
      </div>
    </div>
  );
}
