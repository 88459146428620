import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ErrorIcon from "@mui/icons-material/Error";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import FileReceiver from "./FileReceiver";

export default function TelegramChat(props) {
  const { selectedGameId, handleRequestFile, videoSrc, buttonsData } = props;
  const messages = useSelector((state) => state.user?.messages);
  const [data, setData] = useState([]);

  useEffect(() => {
    const temp = [...messages];

    setData(temp.reverse());
  }, [messages]);

  const getIcon = (str) => {
    switch (str) {
      case "error":
        return <ErrorIcon sx={{ color: "red", fontSize: "14px" }} />;
      case "info":
        return <DoneOutlineIcon sx={{ color: "green", fontSize: "14px" }} />;
      case "help":
        return;
      default:
        break;
    }
  };

  return (
    <div className="tgArea">
      <FileReceiver
        selectedGameId={selectedGameId}
        handleRequestFile={handleRequestFile}
        videoSrc={videoSrc}
        buttonsData={buttonsData}
      />
      {data?.reverse().map((el, i) => {
        return (
          <div key={el.message + i}>
            <div className="tgMessage">
              <div>{el.message}</div>

              {getIcon(el.type)}
            </div>
          </div>
        );
      })}
    </div>
  );
}
