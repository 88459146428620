import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../index.css";
import {
  getGamesForModerator,
  getUsersForModerator,
} from "../redux/asyncThunk/userActions";
import { userSlice } from "../redux/user/userSlice";

const { selectProject } = userSlice;

export default function GameTable() {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(null);

  const data = useSelector((state) => state.user?.games);
  const user = useSelector((state) => state.user?.data);

  useEffect(() => {
    if (user._id) {
      dispatch(getGamesForModerator({ id: user._id }));
      dispatch(getUsersForModerator({ id: user._id }));
    }
  }, [dispatch, user._id]);

  const switchStyles = (value) => {
    switch (value) {
      case value:
        return (
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: "red",
              borderRadius: "50%",
              border: "1px solid black",
            }}
          ></div>
        );

      default:
        break;
    }
  };

  return (
    <>
      <ul>
        <div
          style={{
            display: "flex",
            backgroundColor: "#b6c3cf",
            height: "40px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "250px", textAlign: "center" }}>ID</div>
          <div style={{ width: "250px", textAlign: "center" }}>Автор</div>
          <div style={{ width: "200px", textAlign: "center" }}>Название</div>
          <div style={{ width: "100px", textAlign: "center" }}>Постер</div>
          <div style={{ width: "100px", textAlign: "center" }}>Описание</div>
          <div style={{ width: "200px", textAlign: "center" }}>Доступ</div>
          <div style={{ width: "200px", textAlign: "center" }}>
            Контент для взрослых
          </div>
          <div style={{ width: "200px", textAlign: "center" }}>Опубликован</div>
        </div>
        {data?.map((el, index) => {
          return (
            <div
              className={
                selectedRow === el._id ? "tableLine_active" : "tableLine"
              }
              key={el._id + index}
              onClick={() => {
                setSelectedRow(el._id);
                dispatch(selectProject(el._id));
              }}
            >
              <div style={{ width: "250px", textAlign: "center" }}>
                {el._id}
              </div>
              <div style={{ width: "250px", textAlign: "center" }}>
                {el.owner}
              </div>
              <div style={{ width: "200px", textAlign: "center" }}>
                {el.title}
              </div>
              <div
                className="signalWrapper"
                style={{
                  width: "100px",
                }}
              >
                {switchStyles(el.poster)}
              </div>
              <div className="signalWrapper" style={{ width: "100px" }}>
                {switchStyles(el.description)}
              </div>
              <div style={{ width: "200px", textAlign: "center" }}>
                {el.settings.private_access}
              </div>
              <div style={{ width: "200px", textAlign: "center" }}>
                {el.settings.adult_content}
              </div>
              <div style={{ width: "200px", textAlign: "center" }}>
                {el.settings.published}
              </div>
            </div>
          );
        })}
      </ul>
      <div>Выбранный эл</div>
    </>
  );
}
