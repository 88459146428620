import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import News from "./News";
import GameTable from "./GameTable";
import UserTable from "./UserTable";
import "../index.css";
import {
  getGamesForModerator,
  getUsersForModerator,
} from "../redux/asyncThunk/userActions";

export default function Moderator() {
  const dispatch = useDispatch();

  const [mode, setMode] = useState("games");

  const user = useSelector((state) => state.user?.data);

  useEffect(() => {
    if (user._id) {
      dispatch(getGamesForModerator({ id: user._id }));
      dispatch(getUsersForModerator({ id: user._id }));
    }
  }, [dispatch, user._id]);

  const handleChooseMode = (str) => {
    switch (str) {
      case "news":
        return <News />;
      case "games":
        return <GameTable />;
      case "users":
        return <UserTable />;
      default:
        break;
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <Button
          size="small"
          variant="contained"
          color={mode === "games" ? "success" : undefined}
          onClick={() => setMode("games")}
        >
          Сценарии
        </Button>
        <Button
          size="small"
          variant="contained"
          color={mode === "users" ? "success" : undefined}
          onClick={() => setMode("users")}
        >
          Пользователи
        </Button>
        <Button
          size="small"
          variant="contained"
          color={mode === "news" ? "success" : undefined}
          onClick={() => setMode("news")}
        >
          Новости
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          gap: "20px",
          padding: "20px 20px",
          flexWrap: "wrap",
        }}
      >
        {handleChooseMode(mode)}
      </div>
    </div>
  );
}
