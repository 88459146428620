import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Moderator from "./Moderator";
import Author from "./Author";
import "../index.css";

export default function Dashbord() {
  const isLoading = useSelector((state) => state.user?.status);
  const user = useSelector((state) => state.user?.data);

  const openMode = (str) => {
    switch (str) {
      case "author":
        return <Author />;
      case "moderator":
        return <Moderator />;
      default:
        break;
    }
  };

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "10px 0" }}
      >
        <h1>Рабочий стол</h1>
      </div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "90vh",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>{openMode(user?.type)}</>
      )}
    </div>
  );
}
